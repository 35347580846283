<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-4 pb-0"
      >
        <PageHeader :title="title" class="pt-5" />
        <v-card class="mx-auto mb-4 pt-0" max-width="1200" outlined>
          <v-container class="pt-0">
            <v-row>
              <v-col cols="10" class="py-0" align="left">
                <FiltersSelected
                  :filters="filtersApplyed"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                align="right"
                align-self="center"
                class="py-0 mt-2 mb-2"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="toggleFiltersSelected"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition mode="out-in">
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="applyFilters()"
              >
                <v-row class="pr-4">
                  <!-- Empresa -->
                  <v-col cols="12" md="4" class="py-0">
                    <v-autocomplete
                      ref="empresa-autocomplete"
                      v-model="empresaSelected"
                      :items="empresaItems"
                      return-object
                      item-text="value"
                      item-value="id"
                      outlined
                      clearable
                      :rules="rules.required"
                      dense
                      autocomplete="off"
                      label="Empresa"
                      hint="Nombre"
                      persistent-hint
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- Proveedores -->
                  <v-col cols="12" md="4" class="py-0">
                    <v-autocomplete
                      ref="proveedores-autocomplete"
                      v-model="proveedorSelected"
                      :items="proveedorItems"
                      :search-input.sync="proveedorAutocompleteInput"
                      item-text="value1"
                      item-value="id"
                      :filter="customFilterProveedores"
                      :rules="rules.required"
                      outlined
                      clearable
                      return-object
                      dense
                      autocomplete="off"
                      label="Proveedor"
                      hint="Nombre o código"
                      persistent-hint
                    >
                      <template slot="item" slot-scope="{ item }">
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.value1
                          }}</v-list-item-title>
                          <v-list-item-subtitle
                            >Código: {{ item.value2 }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                      <template v-slot:append>
                        <v-progress-circular
                          indeterminate
                          size="28"
                          v-if="proveedoresLoading"
                          color="primary"
                        ></v-progress-circular>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <!-- Tipo de fecha -->
                  <v-col cols="12" class="py-0" md="4">
                    <v-autocomplete
                      v-model="tipoFechaSelected"
                      :items="tipoFechaItems"
                      :rules="rules.required"
                      label="Tipo de fecha"
                      return-object
                      item-text="value"
                      item-value="id"
                      outlined
                      clearable
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- fecha desde -->
                  <v-col cols="6" md="3" class="py-0">
                    <v-menu
                      ref="fecha-desde"
                      v-model="menuFechaDesde"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaDesdeSelected"
                          label="Fecha desde"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          autocomplete="not"
                          hint="Formato DD/MM/AAAA"
                          @blur="
                            fechaDesde = parseDateToIso(fechaDesdeSelected)
                          "
                          outlined
                          dense
                          clearable
                          v-mask="'##/##/####'"
                          v-on="on"
                          :rules="
                            rules.required.concat(
                              rules.validDate,
                              new Date(parseDateToIso(fechaDesdeSelected)) <=
                                new Date(parseDateToIso(fechaHastaSelected)) ||
                                'Formato incorrecto'
                            )
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaDesde"
                        no-title
                        @change="fechaDesdeSelected = formatDate(fechaDesde)"
                        @input="menuFechaDesde = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- fecha hasta -->
                  <v-col cols="6" md="3" class="py-0">
                    <v-menu
                      ref="fecha-hasta"
                      v-model="menuFechaHasta"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaHastaSelected"
                          label="Fecha hasta"
                          :append-icon="calendarIcon"
                          hint="Formato DD/MM/AAAA"
                          @blur="
                            fechaHasta = parseDateToIso(fechaHastaSelected)
                          "
                          outlined
                          autocomplete="not"
                          dense
                          clearable
                          v-mask="'##/##/####'"
                          v-bind="attrs"
                          v-on="on"
                          :rules="
                            rules.required.concat(
                              rules.validDate,
                              new Date(parseDateToIso(fechaDesdeSelected)) <=
                                new Date(parseDateToIso(fechaHastaSelected)) ||
                                'Formato incorrecto'
                            )
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaHasta"
                        no-title
                        @change="fechaHastaSelected = formatDate(fechaHasta)"
                        @input="menuFechaHasta = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- Monedas -->
                  <v-col
                    cols="3"
                    class="py-0"
                    md="4"
                    v-if="this.paramGralMultimoneda"
                  >
                    <v-autocomplete
                      v-model="monedaSelected"
                      :items="monedaItems"
                      :rules="rules.required"
                      label="Moneda"
                      return-object
                      item-text="value"
                      item-value="id"
                      outlined
                      clearable
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- switch 1 -->
                  <v-col cols="3" class="py-0 my-0">
                    <v-switch
                      class="mt-1"
                      v-model="facturaConVenc"
                      label="Solo factura con vencimiento al:"
                    ></v-switch>
                  </v-col>
                  <!-- Fecha de vencimiento -->
                  <v-col
                    cols="6"
                    md="3"
                    class="py-0"
                    v-if="this.facturaConVenc"
                  >
                    <v-menu
                      ref="fecha-vencimiento"
                      v-model="menuFechaVencimiento"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaVencimientoSelected"
                          label="Fecha de vencimiento"
                          :append-icon="calendarIcon"
                          hint="Formato DD/MM/AAAA"
                          @blur="
                            fechaVencimiento = parseDateToIso(
                              fechaVencimientoSelected
                            )
                          "
                          outlined
                          autocomplete="not"
                          dense
                          clearable
                          v-mask="'##/##/####'"
                          v-bind="attrs"
                          v-on="on"
                          :rules="rules.required"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaVencimiento"
                        no-title
                        @change="
                          fechaVencimientoSelected =
                            formatDate(fechaVencimiento)
                        "
                        @input="menuFechaVencimiento = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- switch 3 -->
                  <v-col cols="3" class="py-0 my-0">
                    <v-switch
                      class="mt-n1"
                      v-model="incluirCompRelacion"
                      label="Incluir comprobantes relacionados"
                    ></v-switch>
                  </v-col>
                  <!-- switch 2 -->
                  <v-col cols="3" class="py-0 my-0">
                    <v-switch
                      class="mt-n1"
                      v-model="soloComprobanteConSaldo"
                      label="Solo comprobante con saldo"
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row class="pr-4 pl-4" justify="end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        size="28"
                        @click="resetForm"
                      >
                        {{ clearFiltersIcon }}
                      </v-icon>
                    </template>
                    <span>Limpiar filtros</span>
                  </v-tooltip>
                  <v-btn
                    color="primary"
                    elevation="2"
                    small
                    type="submit"
                    :disabled="!isFormValid"
                    form="filters-form"
                  >
                    Aplicar
                  </v-btn>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <!-- tablas de informacion -->
        <v-card>
          <!-- tabla de comprobantes -->
          <v-data-table
            :headers="headersCompGral"
            :items="itemsComprobantes"
            height="100%"
            fixed-header
            class="elevation-1"
            :loading="isLoadingTable"
            item-key="mProvId"
          >
            <template v-slot:top>
              <v-toolbar flat dense>
                <v-toolbar-title class="primary--text"
                  >Comprobantes</v-toolbar-title
                >
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="goToVerComprobante(item)"
                  >
                    {{ seeIcon }}
                  </v-icon>
                </template>
                <span>Ver comprobante</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="toggleComprobantesCancelatorios(item)"
                  >
                    {{ comprobanteCancelatorioIcon }}
                  </v-icon>
                </template>
                <span>Ver comprobantes cancelatorios</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
        <v-card class="mt-4">
          <!-- tabla de comprobantes sin relacionar -->
          <v-data-table
            :headers="headersCompSinRelacionar"
            :items="itemsComprobantesSinRelacionar"
            height="100%"
            fixed-header
            class="elevation-1"
            :loading="isLoadingTable"
            item-key="mProvId"
          >
            <template v-slot:top>
              <v-toolbar flat dense>
                <v-toolbar-title class="primary--text"
                  >Comprobantes sin relacionar</v-toolbar-title
                >
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="goToVerComprobante(item)"
                  >
                    {{ seeIcon }}
                  </v-icon>
                </template>
                <span>Ver comprobante</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <!-- saldos totales -->
    <v-row class="mt-3">
      <v-col cols="12" md="3" class="d-flex py-0">
        <v-text-field
          v-model.trim="saldoInicial"
          :label="saldoInicialView"
          outlined
          persistent-hint
          disabled
          filled
          readonly
          dense
          class="right-input"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="d-flex py-0">
        <v-text-field
          v-model.trim="saldoFinal"
          :label="saldoFinalView"
          outlined
          persistent-hint
          disabled
          filled
          readonly
          dense
          class="right-input"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2"></v-col>
      <v-col cols="12" md="4" align="right" class="d-flex py-0">
        <v-tooltip top max-width="40%">
          <template v-slot:activator="{ attrs }">
            <v-btn
              color="primary"
              class="fontsize"
              v-bind="attrs"
              outlined
              :disabled="itemsComprobantes.length == 0"
              @click="exportExcelModelo"
            >
              Exportar comprobantes
            </v-btn>
          </template>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-dialog
      v-if="openCompCancelatorios"
      v-model="openCompCancelatorios"
      max-width="60%"
      @keydown.esc="closeCompCancelatorios"
      persistent
    >
      <ComprobantesCancelatoriosProv
        @closeCompCancelatorios="closeCompCancelatorios"
        :compCancelItems="compCancelItems"
        :compId="compId"
      ></ComprobantesCancelatoriosProv>
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import enums from "@/utils/enums/index.js";
import FiltersSelected from "@/components/shared/FiltersSelected";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import { mapActions } from "vuex";
import Ayuda from "@/components/shared/Ayuda.vue";
import helpersExcel from "@/utils/helpers/importExportExcel";
import ComprobantesCancelatoriosProv from "@/components/modules/proveedores/cuentaCorriente/ComprobantesCancelatoriosProv.vue";
export default {
  name: "CuentaCorrienteProveedores",
  directives: { mask },
  components: {
    PageHeader,
    FiltersSelected,
    Ayuda,
    ComprobantesCancelatoriosProv,
  },
  data: () => ({
    title: "Cuenta corriente de proveedores",
    filtersApplyed: [],
    rules: rules,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    calendarIcon: enums.icons.CALENDAR,
    seeIcon: enums.icons.SEE,
    comprobanteCancelatorioIcon: enums.icons.FILE_CANCEL,
    optionCode: enums.webSiteOptions.CUENTA_CORRIENTE_PROVEEDORES,
    showHelp: false,
    showExpand: false,
    showFilters: true,
    isFormValid: false,
    isLoadingTable: false,
    openCompCancelatorios: false,
    allowedActions: null,
    filtersApplied: [],
    filtrosGuardados: {},
    empresaSelected: null,
    proveedorSelected: null,
    monedaSelected: null,
    tipoFechaSelected: null,
    monedaItems: [],
    empresaItems: [],
    proveedorItems: [],
    tipoFechaItems: [
      { id: 1, value: "Fecha contable", value2: "C" },
      { id: 2, value: "Fecha de comprobante", value2: "F" },
    ],
    menuFechaDesde: false,
    menuFechaHasta: false,
    menuFechaVencimiento: false,
    fechaDesdeSelected: null,
    fechaHastaSelected: null,
    fechaVencimientoSelected: null,
    fechaDesde: null,
    fechaHasta: null,
    fechaVencimiento: null,
    soloComprobanteConSaldo: false,
    incluirCompRelacion: false,
    facturaConVenc: false,
    proveedorAutocompleteInput: null,
    proveedoresLoading: false,
    paramGralMultimoneda: true,
    setInitialParameter: true,
    saldoInicial: 0,
    saldoInicialView: "Saldo inicial",
    saldoFinalView: "Saldo final",
    saldoFinal: 0,
    comprobantesCancelatoriosItems: [],
    itemsComprobantesSinRelacionar: [],
    headersCompSinRelacionar: [
      {
        text: "Tipo de comprobante",
        sortable: false,
        value: "tipoComp",
      },
      {
        text: "Número",
        sortable: false,
        value: "nroComp",
      },
      {
        text: "Fecha de comprobante",
        sortable: false,
        value: "fecha",
      },
      {
        text: "Fecha contable",
        sortable: false,
        value: "fecContable",
      },
      {
        text: "Importe sin relacionar",
        sortable: false,
        value: "cancelado",
        align: "end",
      },
      {
        text: "Acciones",
        value: "actions",
        sortable: false,
        width: "12%",
        align: "end",
      },
    ],
    itemsComprobantes: [],
    headersCompGral: [
      {
        text: "Tipo de comprobante",
        sortable: false,
        value: "tipoComp",
      },
      {
        text: "Número",
        sortable: false,
        value: "nroComp",
      },
      {
        text: "Fecha de comprobante",
        sortable: false,
        value: "fecha",
      },
      {
        text: "Fecha contable",
        sortable: false,
        value: "fechaContable",
      },
      {
        text: "Fecha de vencimiento",
        sortable: false,
        value: "fechaVencimiento",
      },
      {
        text: "Total",
        sortable: false,
        value: "total",
        align: "end",
      },
      {
        text: "Cancelado",
        sortable: false,
        value: "cancelado",
        align: "end",
      },
      {
        text: "Saldo",
        sortable: false,
        value: "saldo",
        align: "end",
      },
      {
        text: "Acciones",
        value: "actions",
        sortable: false,
        width: "12%",
        align: "end",
      },
    ],
  }),
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode", //aparece como pagina visitada si ingreso por url
      this.optionCode
    );
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false,
    });
    this.setSelects();
  },
  watch: {
    proveedorAutocompleteInput(val) {
      if (val && val.length >= 3) {
        this.getProveedoresFilter();
      }
    },
  },
  async mounted() {
    if (this.$store.state.filtersProveedores != null) {
      this.empresaSelected = this.$store.state.filtersProveedores.empresa;
      const responseArray = await this.fetchProveedoresByQuery({
        input: this.$store.state.filtersProveedores.proveedor.value1,
      });
      this.proveedorItems = responseArray;
      this.proveedorSelected = this.proveedorItems[0];
      this.fechaDesde = this.$store.state.filtersProveedores.fechaDesde;
      this.fechaDesdeSelected = this.formatDate(this.fechaDesde);
      this.fechaHasta = this.$store.state.filtersProveedores.fechaHasta;
      this.fechaHastaSelected = this.formatDate(this.fechaHasta);
      this.tipoFechaSelected = this.$store.state.filtersProveedores.tipoFecha;
      this.fechaVencimiento =
        this.$store.state.filtersProveedores.fechaVencimiento;
      this.fechaVencimientoSelected = this.fechaVencimiento
        ? this.formatDate(this.fechaVencimiento)
        : null;
      this.soloComprobanteConSaldo =
        this.$store.state.filtersProveedores.compConSaldo;
      this.facturaConVenc = this.$store.state.filtersProveedores.soloFactura;
      this.incluirCompRelacion =
        this.$store.state.filtersProveedores.incluirCompRelacionados;
      this.monedaSelected = this.$store.state.filtersProveedores.monedaId;
      this.applyFilters();
    }
  },
  methods: {
    ...mapActions({
      fetchProveedoresByQuery: "proveedores/fetchProveedoresByQuery",
      getEntidadesFacturantes: "devengamientos/getEntidadesFacturantes",
      getMonedasTaxonomy: "AdministracionSistema/getMonedasTaxonomy",
      getCtaCteProveedoresByFilter: "proveedores/getCtaCteProveedoresByFilter",
      getParametroGralFechaDesde: "proveedores/getParametroGralFechaDesde",
      getParamGralMultimoneda: "AdministracionSistema/getParamGralMultimoneda",
    }),
    async setSelects() {
      const entidadesFacturantes = await this.getEntidadesFacturantes();
      this.empresaItems = entidadesFacturantes;
      const monedasResponse = await this.getMonedasTaxonomy("habListado");
      this.monedaItems = monedasResponse;
      if (this.setInitialParameter) {
        //solo seteo para pantalla inicial el tipo fecha, la moneda vigente y fecha desde/hasta
        this.tipoFechaSelected = this.tipoFechaItems[1];
        const monedaVig = monedasResponse.find((x) => x.bool == true);
        this.monedaSelected = monedaVig;
        this.setFechaInicial();
      }
      const responseParametros = await this.getParamGralMultimoneda();
      this.paramGralMultimoneda = responseParametros[0].multimonedaParams;
    },
    async setFechaInicial() {
      // fecha de hoy para seteo inicial de fecha hasta
      let today = new Date();
      let año = today.getFullYear();
      let mes = today.getMonth() + 1;
      let dia = today.getDate();
      dia < 10 ? (dia = `0${dia}`) : dia;
      mes < 10 ? (mes = `0${mes}`) : mes; //para que el mes tenga dos digitos y lo reconozca el datepicker
      // seteo inicial de fecha desde la base de datos
      const response = await this.getParametroGralFechaDesde();
      const FechaBaseDatos = response[0].fecDesdePorDefecto;
      this.fechaHasta = `${año}-${mes}-${dia}`;
      this.fechaHastaSelected = this.formatDate(this.fechaHasta);
      this.fechaDesde = this.parseDateToIso(FechaBaseDatos);
      this.fechaDesdeSelected = this.formatDate(this.fechaDesde);
    },
    async applyFilters() {
      this.isLoadingTable = true;
      (this.setInitialParameter = false),
        (this.$store.state.filtersProveedores = null);
      this.customizeFiltersApplied();
      const filters = {
        entFacId: this.empresaSelected.id,
        provId: this.proveedorSelected.id,
        tipoFecha: this.tipoFechaSelected.value2,
        fechaDesde: this.fechaDesde,
        fechaHasta: this.fechaHasta,
        fechaVencimiento: this.facturaConVenc ? this.fechaVencimiento : null,
        compConSaldo: this.soloComprobanteConSaldo,
        soloFactura: this.facturaConVenc,
        incluirCompRelacionados: this.incluirCompRelacion,
        monedaId:
          this.monedaSelected && this.paramGralMultimoneda
            ? this.monedaSelected.id
            : null,
      };
      this.filtrosGuardados = {
        empresa: this.empresaSelected,
        proveedor: this.proveedorSelected,
        tipoFecha: this.tipoFechaSelected,
        fechaDesde: this.fechaDesde,
        fechaHasta: this.fechaHasta,
        fechaVencimiento: this.fechaVencimiento,
        compConSaldo: this.soloComprobanteConSaldo,
        soloFactura: this.facturaConVenc,
        incluirCompRelacionados: this.incluirCompRelacion,
        monedaId:
          this.monedaSelected && this.paramGralMultimoneda
            ? this.monedaSelected
            : null,
      };
      try {
        const response = await this.getCtaCteProveedoresByFilter(filters);
        this.customizeFiltersApplied();
        this.showFilters = false;
        this.itemsComprobantes = response.comprobantes;
        this.itemsComprobantesSinRelacionar = response.comprobantesSinRela;
        this.comprobantesCancelatoriosItems =
          response.comprobantesCancelatorios;
        this.saldoInicialView = `Saldo inicial al: ${this.formatDate(
          this.fechaDesde
        )}`;
        this.saldoFinalView = `Saldo final al: ${this.formatDate(
          this.fechaHasta
        )}`;
        this.saldoInicial = response.saldoInicial;
        this.saldoFinal = response.saldoFinal;
        this.isLoadingTable = false;
      } catch {
        this.isLoadingTable = false;
      }
    },
    customizeFiltersApplied() {
      this.filtersApplyed = [];
      if (this.empresaSelected) {
        this.filtersApplyed.splice(0, 1, {
          key: "empresaSelected",
          label: "Empresa",
          model: this.empresaSelected.value,
        });
      }
      if (this.proveedorSelected) {
        this.filtersApplyed.splice(1, 1, {
          key: "proveedorSelected",
          label: "Proveedor",
          model: this.proveedorSelected.value1,
        });
      }
      if (this.tipoFechaSelected) {
        this.filtersApplyed.splice(2, 1, {
          key: "tipoFechaSelected",
          label: "Tipo de fecha",
          model: this.tipoFechaSelected.value,
        });
      }
      if (this.fechaDesde) {
        this.filtersApplyed.splice(3, 1, {
          key: "fechaDesde",
          label: "Vigencia desde",
          model: this.formatDate(this.fechaDesde),
        });
      }
      if (this.fechaHasta) {
        this.filtersApplyed.splice(4, 1, {
          key: "fechaHasta",
          label: "Vigencia hasta",
          model: this.formatDate(this.fechaHasta),
        });
      }
      if (this.monedaSelected) {
        this.filtersApplyed.splice(6, 1, {
          key: "monedaSelected",
          label: "Moneda",
          model: this.monedaSelected.value,
        });
      }
      if (this.soloComprobanteConSaldo || !this.soloComprobanteConSaldo) {
        this.filtersApplyed.splice(7, 1, {
          key: "soloComprobanteConSaldo",
          label: "Solo comprobante con saldo",
          model: this.soloComprobanteConSaldo ? "SI" : "NO",
        });
      }
      if (this.incluirCompRelacion || !this.incluirCompRelacion) {
        this.filtersApplyed.splice(8, 1, {
          key: "incluirCompRelacion",
          label: "Incluir comprobantes relacionados",
          model: this.incluirCompRelacion ? "SI" : "NO",
        });
      }
      if (this.facturaConVenc || !this.facturaConVenc) {
        this.filtersApplyed.splice(9, 1, {
          key: "facturaConVenc",
          label: this.facturaConVenc
            ? "Solo factura con vencimiento al"
            : "Solo factura con vencimiento",
          model: this.facturaConVenc
            ? this.formatDate(this.fechaVencimiento)
            : "NO",
        });
      }
    },
    toggleFiltersSelected() {
      this.customizeFiltersApplied();
      this.showFilters = !this.showFilters;
    },
    resetForm() {
      this.$refs["filters-form"].reset();
    },
    goToVerComprobante(item) {
      if (item.esOp) {
        this.$router.push({
          name: "VerOrdenDePago",
          params: {
            movProvId: item.mProvId,
            isCtaCte: true,
          },
        });
      } else {
        this.$router.push({
          name: "DetalleComprobanteProv",
          params: {
            movProvId: item.mProvId,
          },
        });
      }
      this.$store.state.filtersProveedores = this.filtrosGuardados;
    },
    toggleComprobantesCancelatorios(item) {
      this.openCompCancelatorios = true;
      this.compCancelItems = this.comprobantesCancelatoriosItems;
      this.compId = item.mProvId;
      this.$store.state.filtersProveedores = this.filtrosGuardados;
    },
    closeCompCancelatorios() {
      this.openCompCancelatorios = false;
    },
    getProveedoresFilter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        if (
          this.proveedorItems?.some(
            (x) =>
              x.value2 == this.proveedorAutocompleteInput ||
              x.value1 == this.proveedorAutocompleteInput
          )
        ) {
          return;
        } else {
          this.proveedoresLoading = true;
          const response = await this.fetchProveedoresByQuery({
            input: this.proveedorAutocompleteInput,
          });
          this.proveedorItems = response;
          this.proveedoresLoading = false;
        }
      }, 1000);
    },
    customFilterProveedores(item, queryText, itemText) {
      return item.value1 || item.value2.indexOf(queryText) > -1;
    },
    exportExcelModelo() {
      let result = [];
      this.itemsComprobantes?.forEach((x) =>
        result.push({
          ["Proveedor"]: x.provNombre,
          ["Tipo de comprobante"]: x.tipoComp,
          ["Número"]: x.nroComp,
          ["Fecha de comprobante"]: x.fecha,
          ["Fecha contable"]: x.fechaContable,
          ["Fecha de vencimiento"]: x.fechaVencimiento,
          ["Total"]: x.total,
          ["Cancelado"]: x.cancelado,
          ["Saldo"]: x.saldo,
          ["Detalle"]: "Comprobante",
        })
      );
      this.itemsComprobantesSinRelacionar?.forEach((x) =>
        result.push({
          ["Proveedor"]: x.provNombre,
          ["Tipo de comprobante"]: x.tipoComp,
          ["Número"]: x.nroComp,
          ["Fecha de comprobante"]: x.fecha,
          ["Fecha contable"]: x.fechaContable,
          ["Importe sin relacionar"]: x.cancelado,
          ["Detalle"]: "Comprobante sin relacionar",
        })
      );
      this.comprobantesCancelatoriosItems?.forEach((x) =>
        result.push({
          ["Proveedor"]: x.provNombre,
          ["Tipo de comprobante"]: x.tipoComp,
          ["Número"]: x.nroComp,
          ["Fecha"]: x.fecha,
          ["Fecha contable"]: x.fecContable,
          ["Importe cancelado"]: x.cancelado,
          ["Detalle"]: "Comprobante cancelatorio",
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado",
      };
      helpersExcel.excelExport(formato, "Comprobantes de proveedor");
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
  },
};
</script>

<style scoped>
.right-input >>> input {
  text-align: right;
}
</style>